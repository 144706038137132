import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import { useSelector } from "react-redux";
import axios from "axios";
import { message } from "antd";
import getUserData from "../utils/userDataService.js";
import "./Wallet.css";

const Wallet = () => {
  const { user } = useSelector((state) => state.user);
  const [tab, setTab] = useState(0);
  const [form, setForm] = useState({ email: "", amount: "" });
  const [payments, setPayments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [balance, setbalance] = useState(0);

  useEffect(() => {
    getUserData(null, null, setbalance);
  }, []);

  const generateOrderId = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const randomNum = Math.floor(1000 + Math.random() * 9000); // Ensures a 4-digit number
    const orderId = `${year}${month}${day}${hours}${minutes}${seconds}${randomNum}`;
    setOrderId(orderId);
  };

  useEffect(() => {
    generateOrderId();
  }, []);

  async function submitPayment() {
    if (form?.amount < 10) {
      return message.error("Enter amount greater or equal to 10rs");
    }
    try {
      setLoading(true);
      const paymentObject = {
        order_id: orderId,
        txn_amount: form?.amount,
        txn_note: "Wallet Topup Note",
        product_name: "Wallet Topup",
        customer_name: form?.customer_name,
        customer_email: form?.customer_email,
        customer_mobile: form?.customer_mobile,
        callback_url: `https://chigongleistore.com/api/payment/check-wallet-payment-received?orderId=${orderId}`,
      };
      const response = await axios.post(
        "/api/payment/add-payment-to-wallet",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
      setLoading(false);
    }
  }

  async function getUserPayments() {
    try {
      const res = await axios.post("/api/payment/get-user-payments", {
        email: user?.email,
      });
      if (res.data.success) {
        setPayments(res.data.data.reverse());
      } else {
        // message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setForm({ ...form, [e.target.name]: e.target.value });
    if (name === "amount") {
      if (value < 10) {
        setError(true);
      } else {
        setError(false);
      }
    }
  }

  useEffect(() => {
    if (user?.email) {
      setForm((prev) => ({
        ...prev,
        customer_email: user?.email,
        customer_name: user?.fname,
        customer_mobile: user?.mobile,
      }));
      getUserPayments();
    }
  }, [user]);

  return (
    <Layout>
      <DashboardLayout>
        <div className="wallet-dash">
          <div className="wallet-dash-card">Balance: {balance}</div>
          <div className="wallet-dash-card" onClick={() => setTab(1)}>
            Add Money
          </div>
          <div className="wallet-dash-card" onClick={() => setTab(0)}>
            Transaction History
          </div>
        </div>

        {/* USER HISTORY */}
        {tab === 0 && (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {payments &&
                payments.map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.amount}</td>
                      <td>
                        {new Date(item?.createdAt).toLocaleString("default", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        })}
                      </td>
                      <td>{item?.status}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}

        {/* BARCODE  */}
        {tab === 1 && (
          <div className="add-money">
            <div className="txn-details">
              <span className="text-danger">
                Note: Your amount must be greater or equal to 10rs
              </span>
              <div className="form-fields mt-3 mb-2">
                <label htmlFor="">Amount</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter amount"
                  name="amount"
                  onChange={handleChange}
                  value={form?.amount}
                />
                <span className="text-danger">
                  {error && "Enter amount greater or equal to 10rs"}
                </span>
              </div>
              <button
                onClick={submitPayment}
                className="w-100 register-btn mt-2"
              >
                Pay Online
              </button>
            </div>
          </div>
        )}
      </DashboardLayout>
    </Layout>
  );
};

export default Wallet;
